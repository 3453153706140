import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { userManager } from './../store';
import Logo from '../images/logo.png';

const styles = (theme) => ({
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '80vh'
	},
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3)
    },
    logo: {
        height: 150,
        margin: theme.spacing(1),
    },
    title: {
        margin: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
});

const LoginPage = ({ classes }) => {
	return (
		<Container maxWidth='xs' className={classes.content}>
			<Paper elevation={1} className={classes.paper}>
				<img src={Logo} alt='logo' className={classes.logo} />
				<Typography variant="h5" color='secondary' className={classes.title}>
					Vuokralaishallinta
				</Typography>
				<Button variant="contained" color="primary" onClick={() => userManager.signinRedirect()} className={classes.submit}>
					Kirjaudu sisään
				</Button>
			</Paper>
		</Container>
	);
}

export default withStyles(styles)(withTheme(LoginPage));
