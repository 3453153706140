import React, { useState } from 'react';
import { Switch, Route, Link as NavLink } from 'react-router-dom';
import { withStyles, withTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Routes
import { General, AccessManagement, DataBank, Reporting, Mailing, EmergencyMessageDialog } from '@project/components';
import DashboardPage from './DashboardPage';

import Logo from '../images/logo.png';

// Icons
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DomainIcon from '@material-ui/icons/Domain';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const drawerWidth = 282;

const styles = (theme) => ({
	root: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
	},
	logo: {
		margin: theme.spacing(1, 2)
	},
	drawer: {
		width: drawerWidth
	},
	page: {
		flex: 1,
		overflow: 'auto'
	},
	content: {
		display: 'block',
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3)
	}
});

const Wrapper = ({ children, classes }) => {
	return (
		<div className={classes.page}>
			<main className={classes.content}>
				{children}
			</main>
		</div>
	);
};

const App = ({ mall, user, match, classes, theme }) => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const small = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<div className={classes.root}>
			{small && (
				<AppBar position='sticky' color='inherit'>
					<Toolbar>
						<IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
							<MenuIcon />
						</IconButton>
						<Typography variant='h6' color='inherit'>
							Kauppakeskus Lauttis
						</Typography>
					</Toolbar>
				</AppBar>
			)}
			<Drawer
				variant={small ? 'temporary' : 'permanent'}
				open={drawerOpen}
				classes={{
					root: classes.drawer,
					paper: classes.drawer
				}}
				onClose={() => setDrawerOpen(false)}
				ModalProps={{
					keepMounted: true
				}}
			>
				<img src={Logo} style={theme.logoSlot} alt='logo' className={classes.logo} />
				<List onClick={() => setDrawerOpen(false)}>
					<ListItem component={NavLink} to={`${match.url}/ostoskeskus`} button>
						<ListItemIcon><HomeIcon /></ListItemIcon>
						<ListItemText primary='Perustiedot' />
					</ListItem>
					<ListItem component={NavLink} to={`${match.url}/tietopankki`} button>
						<ListItemIcon><InfoIcon /></ListItemIcon>
						<ListItemText primary='Tietopankki' />
					</ListItem>
					<ListItem component={NavLink} to={`${match.url}/vuokralaisviestinta`} button>
						<ListItemIcon><ContactMailIcon /></ListItemIcon>
						<ListItemText primary='Vuokralaisviestintä' />
					</ListItem>
					<ListItem component={NavLink} to={`${match.url}/liiketiedot`} button>
						<ListItemIcon><DomainIcon /></ListItemIcon>
						<ListItemText primary='Liiketiedot' />
					</ListItem>
					<ListItem component={NavLink} to={`${match.url}/vuokratiedot`} button>
						<ListItemIcon><AttachMoneyIcon /></ListItemIcon>
						<ListItemText primary='Vuokratiedot' />
					</ListItem>
					<ListItem component={NavLink} to={`${match.url}/raportointi`} button>
						<ListItemIcon><AssignmentIcon /></ListItemIcon>
						<ListItemText primary='Raportointi' />
					</ListItem>
					<ListItem component={NavLink} to={`${match.url}/paasynhallinta`} button>
						<ListItemIcon><VpnKeyIcon /></ListItemIcon>
						<ListItemText primary='Pääsynhallinta' />
					</ListItem>
					<ListItem>
						<EmergencyMessageDialog />
					</ListItem>
					<ListItem>
						<Button variant='text' color='primary' style={{marginLeft: 48}} onClick={() => window.location.href = '/logout'}>
							Kirjaudu ulos
						</Button>
					</ListItem>
				</List>
			</Drawer>
			<Switch>
				<Route path={`${match.url}/tietopankki`} render={() =>
					<Wrapper classes={classes}>
						<DataBank owner={mall.id} />
					</Wrapper>
				} />
				<Route path={`${match.url}/vuokralaisviestinta`} render={() =>
					<Wrapper classes={classes}>
						<Mailing mall={mall} user={user} />
					</Wrapper>
				} />
				<Route path={`${match.url}/liiketiedot`} render={() =>
					<DashboardPage stores={[]} contexts={[mall.id]} />
				} />
				<Route path={`${match.url}/vuokratiedot`} render={() =>
					<DashboardPage stores={[]} contexts={[mall.id]} dpcontent='Rent' />
				} />
				<Route path={`${match.url}/raportointi`} render={() =>
					<Wrapper classes={classes}>
						<Reporting mall={mall} />
					</Wrapper>
				}  />
				<Route path={`${match.url}/paasynhallinta`} render={() =>
					<Wrapper classes={classes}>
						<AccessManagement mall={mall} />
					</Wrapper>
				}  />
				<Route path={`${match.url}/ostoskeskus`} render={() =>
					<Wrapper classes={classes}>
						<General mall={mall} />
					</Wrapper>
				}  />
			</Switch>
		</div>
	);
};

export default withStyles(styles)(withTheme(App));
